import { useState, type ReactNode } from "react";
import { type TwStyle } from "twin.macro";
import { type RuleSet } from "styled-components";
import AddIcon from "@/components/Icons/V2/Edit/add.svg?react";
import RemoveIcon from "@/components/Icons/V2/Edit/remove.svg?react";
import { StyledCard, StyledContentClose, StyledDivider, StyledHeading, StyledSummary } from "./styles";
import Heading from "../Heading";

export interface ICardDismissible {
  title?: string | ReactNode;
  children?: ReactNode;
  defaultOpen?: boolean;
  contentClose?: ReactNode;
  withShadow?: boolean;
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  key?: any;
}

const CardDismissible = ({
  title,
  classes,
  children,
  defaultOpen,
  contentClose,
  withShadow = false,
}: ICardDismissible) => {
  const [expanded, setExpanded] = useState(defaultOpen ?? false);

  return (
    <StyledCard classes={classes} withShadow={withShadow}>
      <details open={defaultOpen} onToggle={(e) => setExpanded((e.currentTarget as HTMLDetailsElement).open)}>
        <StyledSummary>
          <StyledHeading>
            {typeof title === "string" ? <Heading type="h6">{title}</Heading> : title}

            {expanded ? <RemoveIcon /> : <AddIcon />}
          </StyledHeading>
        </StyledSummary>

        {children}
      </details>
      {contentClose && !expanded && (
        <StyledContentClose>
          <StyledDivider />

          {contentClose}
        </StyledContentClose>
      )}
    </StyledCard>
  );
};

export default CardDismissible;
