import _ from "lodash";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {
  availableStoreSliceEmptyState,
  emptyState,
  locationEmptyState,
  locationInitialState,
} from "./states";
import {
  existProductCart,
  setItemValue,
  filterItemsCart,
  setItemComment,
  changeProductStore,
} from "../../utils/cart";
import type { IPrice } from "@/services/checkout/type";

export const cartStoresSlice = createSlice({
  name: "cartStore",
  initialState: emptyState,
  reducers: {
    //  Location Slice Action
    setLocation: (_state: CartStoreAttr, action: PayloadAction<Partial<LocationType>>): CartStoreAttr => {
      if (_state.location === undefined) {
        _state.location = { ...locationInitialState, ...action.payload };
      } else {
        _state.location = Object.assign(_state.location, action.payload);
      }
      return _state;
    },
    emptyLocation: (_state: CartStoreAttr): CartStoreAttr => {
      _state.location = locationEmptyState;
      return _state;
    },
    setInitialLocation: (_state: CartStoreAttr): CartStoreAttr => {
      _state.location = locationInitialState;
      return _state;
    },
    // Available Slice Action
    setAvailableStores: (
      _state: CartStoreAttr,
      action: PayloadAction<setAvailableStoresPayload>
    ): CartStoreAttr => {
      const sorting = [
        "market",
        "restaurant",
        "fit-center",
        "sportswear",
        "sports",
        "books",
        "home-and-kitchen",
        "home-appliances",
        "personal-care",
      ];
      const stores = action.payload.availableStores.stores.sort(
        (a, b) => sorting.indexOf(a.type) - sorting.indexOf(b.type)
      );

      _state.availableStores = {
        ...action.payload.availableStores,
        stores,
        validateAt: action.payload.validateAt,
      };
      return _state;
    },
    emptyAvailability: (_state): CartStoreAttr => {
      _state.availableStores = availableStoreSliceEmptyState;

      return _state;
    },
    setValidateAt: (_state: CartStoreAttr, action: PayloadAction<number | undefined>): CartStoreAttr => {
      _state.availableStores.validateAt = action.payload;
      return _state;
    },
    // CartSlice Actions
    setStoreId: (_state, action: PayloadAction<number>): CartStoreAttr => {
      _state.storeId = Number(action.payload);
      return _state;
    },
    setCommentToProduct: (_state, action: PayloadAction<setCommentToProductPayload>): CartStoreAttr => {
      const productpk = action.payload._pk;
      const productComment = action.payload._comment;
      _state.items = setItemComment(_state.items, productpk, productComment);

      return _state;
    },
    setListItems: (_state, action: PayloadAction<Cart[]>): CartStoreAttr => {
      const newItems = action.payload
        .map((newItem) => (!_.find(_state.items, { pk: newItem.pk }) ? newItem : undefined))
        .filter((element) => element !== undefined) as Cart[];

      _state.items = Array.from(new Set([..._state.items, ...newItems]));
      return _state;
    },
    setCartStores: (state, action: PayloadAction<CartInput>): CartStoreAttr => {
      const cart = action.payload;
      const storeId = action.payload.storeId;

      if (storeId !== -1) {
        state.last_change_items = new Date().getTime();

        // When is the first time
        if (existProductCart(state.items, cart.item.pk)) {
          state.items = setItemValue(state.items, cart.item.pk, cart.quantity);
          state.items = filterItemsCart(state.items);

          if (state.items.length === 0) {
            if (state.availableStores.stores.length > 0) {
              state.storeId = state.availableStores.stores[0].id;
            } else {
              state.storeId = undefined;
            }
          }
          return state;
        }

        const newProduct = {
          comment: cart.item.comment,
          pk: cart.item.pk,
          recommId: cart.recommId,
          availability: cart.availability,
          quantity: cart.quantity,
          details: {
            sku: cart.item.details.sku,
            name: cart.item.details.name,
            slug: cart.item.details.slug,
            containsAlcohol: cart.item.details.containsAlcohol,
            brand: {
              slug: cart.item.details.brand.slug,
              name: cart.item.details.brand?.name,
            },
            thumbnail: {
              url: cart.item.details.thumbnail.url,
              alt: cart.item.details.thumbnail.alt,
            },
          },
        };

        if (!state.storeId || state.storeId === -1) {
          state.items.push(newProduct);
          state.storeId = storeId;

          return state;
        } else if (state.storeId === storeId) {
          state.items.push(newProduct);

          return state;
        }
        return state;
      }
      return state;
    },
    setQuantityProduct: (_state, action: PayloadAction<setQuantityProductPayload>): CartStoreAttr => {
      const productpk = action.payload._pk;
      const productQuantity = action.payload.quantity;

      _state.items = setItemValue(_state.items, productpk, productQuantity);
      _state.items = filterItemsCart(_state.items);

      return _state;
    },
    changeProductStore: (state, action: PayloadAction<changeProductStorePayload>): CartStoreAttr => {
      const productPkSearched = action.payload.pkSearched;
      const newProductInfo = action.payload.newProductStore;

      state.last_change_items = new Date().getTime();
      state.items = changeProductStore(state.items, productPkSearched, newProductInfo);

      return state;
    },
    emptyCart: (_state, action: PayloadAction<{ emptyStore?: boolean }>): CartStoreAttr => {
      _state.items = [];
      if (action.payload?.emptyStore) {
        _state.storeId = undefined;
      }
      return _state;
    },
    setInitialState: (): CartStoreAttr => emptyState,

    // v2

    addProduct: (state, action: PayloadAction<Cart>) => {
      const stepQty = action.payload.stepQty ?? 1;
      state.items.push({
        ...action.payload,
        store: {
          pk: state.storeId,
        },
        quantity: stepQty,
      });
    },

    // addProducts: (state, action: PayloadAction<Cart[]>) => {
    //   state.items = {
    //     ...state.items,
    //     ...action.payload.map((item) => ({
    //       ...item,
    //       store: {
    //         pk: state.storeId,
    //       },
    //       quantity: 1,
    //     })),
    //   };
    // },

    removeProduct: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => String(item.pk) !== String(action.payload));
    },
    removeProducts: (state, action: PayloadAction<string[]>) => {
      state.items = state.items.filter((item) => !action.payload.includes(item.pk));
    },
    changeQuantity: (
      state,
      action: PayloadAction<{
        productId: number;
        quantity: number;
      }>
    ) => {
      const { productId, quantity } = action.payload;
      const items = state.items;
      for (const item of items) {
        if (String(item.pk) === String(productId)) {
          item.quantity = quantity;
          break;
        }
      }
      state.items = items;
    },
    changeQuatityAvailable: (
      state,
      action: PayloadAction<{
        productId: number;
        maxQtyToOrder: number;
        isAvailable: boolean;
        price?: IPrice;
        preceUndiscounted?: IPrice;
        onSale?: boolean;
        stepQty?: number;
        measureTypeDisplayShort?: string | null;
      }>
    ) => {
      const { productId, maxQtyToOrder, isAvailable, preceUndiscounted, price, onSale } = action.payload;
      const itemIndex = state.items.findIndex((item) => String(item.pk) === String(productId));

      if (itemIndex !== -1) {
        const updatedItem = { ...state.items[itemIndex] };

        updatedItem.availability = {
          ...updatedItem.availability,
          maxQtyToOrder,
          available: isAvailable,
        };

        if (price !== undefined) {
          updatedItem.availability.price = price;
        }

        if (preceUndiscounted !== undefined) {
          updatedItem.availability.priceUndiscounted = preceUndiscounted;
        }

        if (onSale !== undefined) {
          updatedItem.availability.onSale = onSale;
        }

        if (!!updatedItem.availability.priceUndiscounted.gross && !!updatedItem.availability.price.gross) {
          updatedItem.availability.discount = {
            gross: updatedItem.availability.priceUndiscounted.gross - updatedItem.availability.price.gross,
            currency: updatedItem.availability.price.currency,
          };
        }

        if (action.payload.stepQty !== undefined) {
          updatedItem.stepQty = action.payload.stepQty;
        }

        if (action.payload.measureTypeDisplayShort !== undefined) {
          updatedItem.details.measureTypeDisplayShort = action.payload.measureTypeDisplayShort;
        }

        state.items[itemIndex] = updatedItem;
      }
    },
    setLastUsedAddress: (state, action: PayloadAction<{ city: string; address: any }>) => {
      const { city, address } = action.payload;

      // Asegurarse de crear un nuevo objeto en lugar de mutar el estado directamente
      state.lastUsedAddresses = {
        ...state.lastUsedAddresses,
        [city]: address,
      };
    },
    emptyLastUsedAddresses: (state) => {
      state.lastUsedAddresses = {};
    },
  },
});

export const cartStoresActions = cartStoresSlice.actions;
