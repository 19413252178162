export interface CityAddress {
  city: string;
  address: string;
  to: string;
  hour: string;
  weekdayOpenTime: string;
  weekdayCloseTime: string;
  sundayOpenTime: string;
  sundayCloseTime: string;
}

export default [
  {
    city: "Barranquilla",
    address: "Cra 51 # 82-223",
    to: "https://maps.app.goo.gl/rqZ911Gd6M9LBRLYA",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
    weekdayOpenTime: "08:00",
    weekdayCloseTime: "20:00",
    sundayOpenTime: "10:00",
    sundayCloseTime: "19:00",
  },
  {
    city: "Bogotá",
    address: "Calle 93a #11-46",
    to: "https://maps.app.goo.gl/dbjBn4ZmpyAx2tv77",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
    weekdayOpenTime: "08:00",
    weekdayCloseTime: "20:00",
    sundayOpenTime: "10:00",
    sundayCloseTime: "19:00",
  },
  {
    city: "Medellín",
    address: "Cra 30a #9-68",
    to: "https://maps.app.goo.gl/DoNmXsuXXoWLJ5YP7",
    hour: "Lun - Sab 8 am - 9 pm | Dom  8 am - 7 pm",
    weekdayOpenTime: "08:00",
    weekdayCloseTime: "21:00",
    sundayOpenTime: "08:00",
    sundayCloseTime: "19:00",
  },
];
