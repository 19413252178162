import { memo, useCallback } from "react";
import tw, { theme } from "twin.macro";
import type { IProductCardGallery } from "@/types/productGallery";

import Image from "@atoms/Image";
import Heading from "@atoms/Heading";
import Paragraph from "@atoms/Paragraph";
import Label from "@atoms/Label";
import CounterInput from "@atoms/CounterInput";
import Button from "@atoms/Button";
import ProductCardTag from "@atoms/ProductCardTag";

import { formatCurrency } from "@/utils/money";
import useProductCardGallery from "@/hooks/useProductCardGallery";
import IconMessageAlertSquare from "@iconsV2/Communication/message-alert-square.svg?react";
import IconCart from "@iconsV2/Interrface/cart-01.svg?react";
import IconMessageAlertCircle from "@iconsV2/Communication/message-alert-circle.svg?react";
import IconTrash from "@iconsV2/Interrface/trash-02.svg?react";
import type { TSize } from "@/types/common";
import { MAX_UNIT_QUANTITY } from "@/recourses/constants";
import { getDisplayBrand } from "@/utils/getDisplayBrand";

import {
  StyledWrapperFigure,
  StyledWrapperHead,
  StyledWrapperPGCardSearch,
  StyledWrapperBody,
  StyledWrapperContent,
  StyledWrapperHelpMessage,
} from "./styles";
import { Link } from "react-router-dom";

interface IProductCardGallerySearch extends Omit<IProductCardGallery, "sizeVariant"> {
  showButtonCart?: boolean;
  showUnits?: boolean;
  sizeCounterInput?: TSize;
  extraValidation?: {
    units?: boolean;
  };
}

const ProductCardGallerySearch = ({
  product,
  oldDataProduct,
  showButtonCart = true,
  showUnits = false,
  active = false,
  disabled = false,
  sizeCounterInput = "medium",
  extraValidation = {
    units: false,
  },
}: IProductCardGallerySearch) => {
  const {
    // states:
    showInput,
    productQuantity,
    isProductAvailable,
    percentageDiscount,

    // functions:
    getGramans,
    handleAddToCart,
    handledToCleanCart,
    handleIncrement,
    handleToDecrement,
    handleToChangeInput,
    handleGoToBrand,
  } = useProductCardGallery({
    product,
    oldDataProduct: oldDataProduct!,
  });

  const Tag = useCallback(() => {
    if (product.bestSeller) {
      return <ProductCardTag type="bestSeller" tw="rounded-round shadow-none" />;
    }

    if (product.isNew) {
      return <ProductCardTag type="new" />;
    }

    if (product.isPromoted) {
      return <ProductCardTag type="promoted" />;
    }

    if (product.availabilityOnSaleOnline && product.customLabel) {
      return (
        <ProductCardTag
          tw="rounded-round shadow-none"
          type="customLabel"
          label={product.customLabel}
          labelBackground={product.customLabelBackgroundColor}
        />
      );
    }

    return null;
  }, [
    product.bestSeller,
    product.isNew,
    product.isPromoted,
    product.availabilityOnSaleOnline,
    product.customLabel,
  ]);

  const RemainingUnit = useCallback(
    ({ error }: { error?: boolean }) => {
      return (
        <StyledWrapperHelpMessage>
          <IconMessageAlertCircle css={error && tw`[& > path]:(stroke-error-40)`} />
          <Paragraph sizeVariant="sm" color={error ? theme`colors.error.40` : theme`colors.neutral.10`}>
            Quedan {product.quatityAvailable} {getGramans(product.quatityAvailable)}
          </Paragraph>
        </StyledWrapperHelpMessage>
      );
    },
    [product?.quatityAvailable]
  );

  const HelpMessage = useCallback(() => {
    if (!isProductAvailable) {
      return (
        <StyledWrapperHelpMessage>
          <IconMessageAlertSquare tw="[&>path]:stroke-error-40" />
          <Paragraph sizeVariant="sm" size="medium" color={theme`colors.error.40`}>
            Sin unidades
          </Paragraph>
        </StyledWrapperHelpMessage>
      );
    }

    if (product.quatityAvailable < MAX_UNIT_QUANTITY || extraValidation?.units) {
      return <RemainingUnit error={extraValidation.units} />;
    }
    return null;
  }, [product?.quatityAvailable, showInput, isProductAvailable, extraValidation?.units]);

  return (
    <StyledWrapperPGCardSearch $isPromoted={product.isPromoted} $active={active}>
      <Link
        to={`/p/${product.slug}`}
        title={product.name}
        state={{
          product,
        }}
      >
        <StyledWrapperFigure $disabled={disabled}>
          <Image src={product.imagen} alt={product.name} />
        </StyledWrapperFigure>
      </Link>

      <StyledWrapperContent>
        <StyledWrapperHead>
          <div>
            <Heading type="h6" disabled={disabled} onClick={handleGoToBrand} tw="cursor-pointer w-min">
              {getDisplayBrand(product.brand)}
            </Heading>
            <Paragraph
              sizeVariant="sm"
              disabled={disabled}
              as={Link}
              to={`/p/${product.slug}`}
              title={product.name}
              state={{
                product,
              }}
            >
              {product.name}
            </Paragraph>
          </div>

          <Tag />
        </StyledWrapperHead>

        <StyledWrapperBody>
          <div tw="flex gap-xxs items-center">
            <Label sizeVariant="lg" color={theme`colors.primaryV2.10`} disabled={disabled}>
              {formatCurrency(product.pricePerStepQty ?? 0)}
            </Label>

            {product.availabilityOnSaleOnline && (
              <div tw="flex flex-col items-center">
                <Paragraph sizeVariant="sm" color={theme`colors.neutral.30`} tw="line-through">
                  {formatCurrency(product.originalPricePerStepQty ?? 0)}
                </Paragraph>
                <Paragraph sizeVariant="sm" color={theme`colors.neutral.30`}>
                  {percentageDiscount}%
                </Paragraph>
              </div>
            )}
          </div>
          <div tw="w-min flex flex-col gap-xxs items-end">
            {showButtonCart && isProductAvailable && (
              <>
                {showInput ? (
                  <CounterInput
                    showIconRemove
                    tw="[&>div]:gap-xxs w-[9.5rem] medium:(w-[9.8rem] large:(w-[10rem]))"
                    value={String(productQuantity)}
                    onRemove={handledToCleanCart}
                    onIncrement={() => handleIncrement(product.id)}
                    onDecrement={handleToDecrement}
                    onChange={(value) => handleToChangeInput(value as number)}
                    maxUnits={product.quatityAvailable}
                    id="counter-input"
                    error={productQuantity > product.quatityAvailable}
                    size={sizeCounterInput}
                    measureType={product.measureTypeShort ?? ""}
                    stepQty={product.stepQty}
                  />
                ) : (
                  <Button
                    sizeVariant="md"
                    endIcon={<IconCart />}
                    disabled={!isProductAvailable}
                    onClick={handleAddToCart}
                  />
                )}
              </>
            )}

            {showUnits && (
              <div tw="flex items-center w-max gap-xs">
                <HelpMessage />

                {!isProductAvailable && showButtonCart && (
                  <Button
                    endIcon={<IconTrash />}
                    onClick={handledToCleanCart}
                    color="secondary"
                    variant="outlined"
                    sizeVariant="md"
                  />
                )}
              </div>
            )}
          </div>
        </StyledWrapperBody>
      </StyledWrapperContent>
    </StyledWrapperPGCardSearch>
  );
};

export default memo(ProductCardGallerySearch, (prevProps, nextProps) => {
  return (
    prevProps.product.id === nextProps.product.id &&
    prevProps.product.quatityAvailable === nextProps.product.quatityAvailable &&
    prevProps.disabled === nextProps.disabled
  );
});
