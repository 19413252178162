/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState, type CSSProperties } from "react";
import { theme } from "twin.macro";
import Label from "../Label";
import Paragraph from "../Paragraph";

import IconAdd from "@iconsV2/Edit/add.svg?react";
import IconRemove from "@iconsV2/Edit/remove.svg?react";
import IconTrash from "@iconsV2/Interrface/trash-02.svg?react";
import type { TSize } from "@/types/common";

import {
  StyledInput,
  StyledWrapperBtnDecrement,
  StyledWrapperBtnIncrement,
  StyledWrapperBtnRemove,
  StyledWrapperCounterInput,
  StyledWrapperInput,
} from "./styles";

export interface CounterInputProps {
  label?: string;
  helpText?: string;
  error?: boolean;
  size?: TSize;
  showIconRemove?: boolean;
  measureType?: string;
  value: string;
  onIncrement: () => void;
  onDecrement: () => void;
  onRemove: () => void;
  onChange: (value: number | string) => void;
  maxUnits?: number;
  width?: CSSProperties["width"];
  justifyContent?: CSSProperties["justifyContent"];
  stepQty?: number;
  [key: string]: any;
}

const MAX_LENGTH = 6;
const MAX_LENGTH_STRING = 99_999;

const CounterInput = ({
  label,
  helpText,
  value,
  error = false,
  size,
  showIconRemove = true,
  maxUnits = 1000,
  width,
  justifyContent,
  measureType,
  stepQty = 1,
  onIncrement = () => {
    throw new Error("onIncrement is not implemented");
  },
  onDecrement = () => {
    throw new Error("onDecrement is not implemented");
  },
  onRemove = () => {
    throw new Error("onRemove is not implemented");
  },
  onChange = () => {
    throw new Error("onChange is not implemented");
  },
  ...rest
}: CounterInputProps) => {
  // Función que formatea el valor añadiendo una unidad de medida si está definida
  const formattedValue = (value: string): string => {
    return measureType && stepQty >= 2 ? `${value} ${measureType}` : value;
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stepQty >= 2) {
      return;
    }

    const quantity = e.target.value;

    if (quantity === "") {
      onChange("");
      return;
    }

    if (quantity.length > MAX_LENGTH) {
      const numQuantity = Number(quantity.slice(0, MAX_LENGTH));
      if (!isNaN(numQuantity)) {
        onChange(numQuantity);
      }
      return;
    }

    const numQuantity = Number(quantity);
    if (!isNaN(numQuantity) && numQuantity >= 0) {
      onChange(numQuantity);
    }
  };

  const handleIncrement = () => {
    if (Number(value) + 1 > MAX_LENGTH_STRING) {
      return;
    }
    onIncrement();
  };

  const handleDecrement = () => {
    if (Number(value) - 1 < 0) {
      return;
    }
    onDecrement();
  };

  return (
    <StyledWrapperCounterInput {...rest} $hasValue={!!value?.length}>
      {label && <Label sizeVariant="md">{label}</Label>}

      <StyledWrapperInput $width={width} $justifyContent={justifyContent}>
        <div>
          <StyledWrapperBtnDecrement onClick={handleDecrement} $size={size} disabled={value === "0"}>
            {value === "1" ? <IconTrash /> : <IconRemove />}
          </StyledWrapperBtnDecrement>

          <StyledInput
            type="text"
            value={formattedValue(value)}
            onChange={handleChangeInput}
            maxLength={MAX_LENGTH + (measureType ? 2 : 0)}
            $error={error}
            $size={size}
            autoComplete="off"
            disabled={stepQty >= 2}
          />

          <StyledWrapperBtnIncrement onClick={handleIncrement} $size={size}>
            <IconAdd />
          </StyledWrapperBtnIncrement>
        </div>

        {showIconRemove && (
          <StyledWrapperBtnRemove onClick={onRemove}>
            <IconTrash />
          </StyledWrapperBtnRemove>
        )}
      </StyledWrapperInput>

      {helpText && (
        <Paragraph sizeVariant="sm" color={error ? theme`colors.error.40` : theme`colors.neutral.10`}>
          {helpText}
        </Paragraph>
      )}
    </StyledWrapperCounterInput>
  );
};

export default CounterInput;
