import { memo, useCallback } from "react";
import tw, { theme } from "twin.macro";

import Label from "@components/Atoms/Label";
import Button from "@components/Atoms/Button";
import Heading from "@components/Atoms/Heading";
import Paragraph from "@components/Atoms/Paragraph";
import CounterInput from "@components/Atoms/CounterInput";
import ProductCardTag from "@components/Atoms/ProductCardTag";
import Image from "@/components/Atoms/Image";

import { formatCurrency } from "@utils/money";

import useProductCardGallery from "@hooks/useProductCardGallery";
import type { IProductCardGallery } from "@/types/productGallery";

import IconCart from "@iconsV2/Interrface/cart-01.svg?react";
import IconMessageAlertCircle from "@iconsV2/Communication/message-alert-circle.svg?react";
import IconMessageAlertSquare from "@iconsV2/Communication/message-alert-square.svg?react";

import {
  StyleButton,
  StyledWrapperButton,
  StyledWrapperCard,
  StyledWrapperContent,
  StyledWrapperContentDiscount,
  StyledWrapperContentPrice,
  StyledWrapperContentText,
  StyledWrapperFigure,
  StyledWrapperHelpMessage,
  StyledWrapperTagCustom,
  StyledWrapperTagNewDiscount,
} from "./styles";
import { MAX_UNIT_QUANTITY } from "@/recourses/constants";
import { getDisplayBrand } from "@/utils/getDisplayBrand";

const ProductCardGallery = ({
  product,
  oldDataProduct,
  sizeVariant,
  size,
  orientation = "vertical",
  recommId,
  active = false,
  disabled = false,
  withBorder = true,
  ...rest
}: IProductCardGallery) => {
  if (!sizeVariant) {
    throw new TypeError("sizeVariant is required");
  }

  const {
    // states:
    showInput,
    productQuantity,
    isProductAvailable,
    percentageDiscount,

    // functions:
    getGramans,
    handleAddToCart,
    handledToCleanCart,
    handleIncrement,
    handleToDecrement,
    handleToChangeInput,
    handleGoToBrand,
  } = useProductCardGallery({
    product,
    oldDataProduct: { ...oldDataProduct!, recommId },
  });

  const isSm = sizeVariant === "sm";
  const isLg = sizeVariant === "lg";
  const isVertical = orientation === "vertical";
  const isHorizontal = !isVertical;
  const isHorizontalLg = isLg && isHorizontal;

  const TagCustomLabelBesSeller = useCallback(() => {
    if (product.bestSeller) {
      return (
        <span className="wrapper-custom-label">
          <ProductCardTag
            type="bestSeller"
            css={isHorizontalLg ? tw`rounded-round shadow-none` : undefined}
          />
        </span>
      );
    }
    if (product.availabilityOnSaleOnline && product.customLabel) {
      return (
        <span className="wrapper-custom-label">
          <ProductCardTag
            type="customLabel"
            css={isHorizontalLg ? tw`rounded-round shadow-none` : ``}
            label={product.customLabel}
            labelBackground={product.customLabelBackgroundColor}
          />
        </span>
      );
    }
    return null;
  }, [
    product?.bestSeller,
    product?.availabilityOnSaleOnline,
    product?.customLabel,
    product?.customLabelBackgroundColor,
    isHorizontalLg,
  ]);

  const TagNewDiscount = useCallback(() => {
    if (product.isNew) {
      return <ProductCardTag type="new" />;
    }

    if (product.availabilityOnSaleOnline) {
      return <ProductCardTag type="discount" amount={String(percentageDiscount)} />;
    }

    return null;
  }, [product?.isNew, product?.availabilityOnSaleOnline, percentageDiscount]);

  const RemainingUnit = useCallback(
    ({ error }: { error?: boolean }) => {
      return (
        <StyledWrapperHelpMessage>
          <IconMessageAlertCircle css={error && tw`[& > path]:(stroke-error-40)`} />
          <Paragraph
            sizeVariant="sm"
            size={size}
            color={error ? theme`colors.error.40` : theme`colors.neutral.10`}
          >
            Quedan {product.quatityAvailable} {getGramans(product.quatityAvailable)}
          </Paragraph>
        </StyledWrapperHelpMessage>
      );
    },
    [product?.quatityAvailable]
  );

  const HelpMessage = useCallback(() => {
    if (!isProductAvailable) {
      return (
        <StyledWrapperHelpMessage>
          <IconMessageAlertSquare tw="[&>path]:stroke-error-40" />
          <Paragraph sizeVariant="sm" size={size} color={theme`colors.error.40`}>
            No disponible
          </Paragraph>
        </StyledWrapperHelpMessage>
      );
    }

    if (product.quatityAvailable < MAX_UNIT_QUANTITY || productQuantity > product.quatityAvailable) {
      return <RemainingUnit error={productQuantity > product.quatityAvailable} />;
    }
    return null;
  }, [product?.quatityAvailable, productQuantity]);

  if (!product) {
    return null;
  }

  if (isSm && isHorizontal) {
    return (
      <StyledWrapperCard
        $size={size}
        $orientation={orientation}
        $sizeVariant={sizeVariant}
        $showInputCounter={showInput}
        $isPromoted={product.isPromoted}
        $active={active}
        to={recommId ? `/p/${product.slug}?recommId=${recommId}` : `/p/${product.slug}`}
        title={product.name}
        $withBorder={withBorder}
        state={{
          product,
        }}
        {...rest}
      >
        <StyledWrapperContent>
          <StyledWrapperFigure $disabled={disabled || !isProductAvailable}>
            <Image src={product.imagen} alt={product.name} title={product.name} effect="blur" />
          </StyledWrapperFigure>

          <StyledWrapperContentText>
            <Paragraph
              sizeVariant="sm"
              size={size}
              disabled={disabled || !isProductAvailable}
              tw="line-clamp-3"
            >
              {product.name}
            </Paragraph>
          </StyledWrapperContentText>
        </StyledWrapperContent>
        <StyledWrapperContent>
          {!showInput && (
            <StyledWrapperContentPrice>
              <StyledWrapperContentText>
                <Label
                  sizeVariant="lg"
                  size={size}
                  color={theme`colors.primaryV2.10`}
                  disabled={disabled || !isProductAvailable}
                >
                  {formatCurrency(product.pricePerStepQty ?? 0)}
                </Label>

                {product.availabilityOnSaleOnline && (
                  <StyledWrapperContentDiscount>
                    <Paragraph
                      sizeVariant="sm"
                      size={size}
                      color={theme`colors.neutral.30`}
                      tw="line-through"
                    >
                      {formatCurrency(product.originalPricePerStepQty ?? 0)}
                    </Paragraph>
                    {product.isNew && (
                      <Paragraph sizeVariant="sm" size={size} color={theme`colors.neutral.30`}>
                        {percentageDiscount}%
                      </Paragraph>
                    )}
                  </StyledWrapperContentDiscount>
                )}
              </StyledWrapperContentText>
            </StyledWrapperContentPrice>
          )}

          <StyledWrapperButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <StyleButton>
              {showInput ? (
                <CounterInput
                  className="counter-input"
                  value={String(productQuantity)}
                  showIconRemove={false}
                  size={size}
                  onRemove={handledToCleanCart}
                  onIncrement={() => handleIncrement(product.id)}
                  onDecrement={handleToDecrement}
                  onChange={(value) => handleToChangeInput(value as number)}
                  maxUnits={product.quatityAvailable}
                  id="counter-input"
                  error={productQuantity > product.quatityAvailable}
                  measureType={product.measureTypeShort ?? ""}
                  stepQty={product.stepQty}
                />
              ) : (
                <Button
                  onClick={handleAddToCart}
                  sizeVariant="lg"
                  size={size}
                  fullWidth={!isSm || isVertical}
                  endIcon={<IconCart />}
                  disabled={!isProductAvailable}
                />
              )}
            </StyleButton>
          </StyledWrapperButton>
        </StyledWrapperContent>
      </StyledWrapperCard>
    );
  }

  return (
    <StyledWrapperCard
      $size={size}
      $orientation={orientation}
      $sizeVariant={sizeVariant}
      $showInputCounter={showInput}
      $isPromoted={product.isPromoted}
      $active={active}
      to={recommId ? `/p/${product.slug}?recommId=${recommId}` : `/p/${product.slug}`}
      title={product.name}
      $withBorder={withBorder}
      state={{
        product,
      }}
      {...rest}
    >
      <StyledWrapperFigure $disabled={disabled || !isProductAvailable}>
        {!isSm && !isHorizontalLg && <TagCustomLabelBesSeller />}
        <Image src={product.imagen} alt={product.name} title={product.name} effect="blur" />
        {!isSm && product.isPromoted && (
          <span className="wrapper-promoted">
            <ProductCardTag type="promoted" />
          </span>
        )}
      </StyledWrapperFigure>

      <StyledWrapperContent>
        <StyledWrapperContentText>
          {!isSm && (
            <Heading
              type={"h6"}
              disabled={disabled || !isProductAvailable}
              onClick={handleGoToBrand}
              tw="cursor-pointer"
            >
              {getDisplayBrand(product.brand)}
            </Heading>
          )}
          <Paragraph
            sizeVariant="sm"
            size={size}
            disabled={disabled || !isProductAvailable}
            tw="line-clamp-3"
          >
            {product.name}
          </Paragraph>
        </StyledWrapperContentText>

        <StyledWrapperContentPrice>
          <StyledWrapperContentText>
            <Label
              sizeVariant="lg"
              size={size}
              color={theme`colors.primaryV2.10`}
              disabled={disabled || !isProductAvailable}
            >
              {formatCurrency(product.pricePerStepQty ?? 0)}
            </Label>

            {product.availabilityOnSaleOnline && (
              <StyledWrapperContentDiscount>
                <Paragraph sizeVariant="sm" size={size} color={theme`colors.neutral.30`} tw="line-through">
                  {formatCurrency(product.originalPricePerStepQty ?? 0)}
                </Paragraph>
                {product.isNew && (
                  <Paragraph sizeVariant="sm" size={size} color={theme`colors.neutral.30`}>
                    {percentageDiscount}%
                  </Paragraph>
                )}
              </StyledWrapperContentDiscount>
            )}
          </StyledWrapperContentText>

          {!isSm && (
            <StyledWrapperTagNewDiscount>
              <TagNewDiscount />
            </StyledWrapperTagNewDiscount>
          )}
        </StyledWrapperContentPrice>

        <StyledWrapperButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <HelpMessage />

          <StyleButton>
            {isProductAvailable ? (
              <>
                {showInput ? (
                  <CounterInput
                    value={String(productQuantity)}
                    showIconRemove={!isSm}
                    size={size}
                    onRemove={handledToCleanCart}
                    onIncrement={() => handleIncrement(product.id)}
                    onDecrement={handleToDecrement}
                    onChange={(value) => handleToChangeInput(value as number)}
                    maxUnits={product.quatityAvailable}
                    id="counter-input"
                    error={productQuantity > product.quatityAvailable}
                    measureType={product.measureTypeShort ?? ""}
                    stepQty={product.stepQty}
                    fullWidth
                  />
                ) : (
                  <Button
                    sizeVariant="md"
                    size={size}
                    fullWidth={!isSm || isVertical}
                    endIcon={<IconCart />}
                    disabled={!isProductAvailable}
                    onClick={handleAddToCart}
                  >
                    {isSm && isHorizontal ? "" : "Agregar"}
                  </Button>
                )}
              </>
            ) : (
              <div />
            )}

            {isHorizontalLg && (
              <StyledWrapperTagCustom>
                <TagCustomLabelBesSeller />
              </StyledWrapperTagCustom>
            )}
          </StyleButton>
        </StyledWrapperButton>
      </StyledWrapperContent>
    </StyledWrapperCard>
  );
};

export default memo(ProductCardGallery, (prevProps, nextProps) => {
  return (
    prevProps.product.id === nextProps.product.id &&
    prevProps.sizeVariant === nextProps.sizeVariant &&
    prevProps.orientation === nextProps.orientation &&
    prevProps.product.quatityAvailable === nextProps.product.quatityAvailable
  );
});
